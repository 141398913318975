// @flow
import * as React from 'react';
import DefaultLayout from '../layouts/default';
import './demo.less';

export default class Demo extends React.Component<void> {
  isDisablingInput: boolean = false;
  gameContainer: { current: null | HTMLDivElement } = React.createRef();

  componentDidMount() {
    if (document.querySelector('#unity-loader')) {
      // Already loaded
      return;
    }

    const body = document.body;
    const container = this.gameContainer.current;
    const script = document.createElement('script');
    script.src = '/unity/Build/UnityLoader.js';
    script.id = 'unity-loader';

    // Check because Flow wants us to
    if (!body || !container) {
      return;
    }

    body.appendChild(script);
    script.onload = () => {
      if (!window || !window.UnityLoader) {
        return;
      }

      window.UnityLoader.instantiate(container.id, '/unity/Build/unity.json');
    };
  }

  keyDown = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    // Unity doesn't disable keyboard input events on mobile browsers so we have to do
    // it manually, otherwise spacebar will scroll the screen
    if (this.isDisablingInput) {
      e.preventDefault();
    }
  };

  focusContainer = (e: SyntheticFocusEvent<HTMLDivElement>) => {
    this.isDisablingInput = true;
  };

  blurContainer = (e: SyntheticFocusEvent<HTMLDivElement>) => {
    this.isDisablingInput = false;
  };

  render() {
    return (
      <DefaultLayout pageTitle="Platform Pixels Demo" pageDescription="An early game preview">
        <div className="demo-page">
          <div tabIndex={-1}
               ref={this.gameContainer}
               id="unity-container"
               className="demo-page__game"
               onKeyDown={this.keyDown}
               onBlur={this.blurContainer}
               onFocus={this.focusContainer}>
            Loading demo...
          </div>
        </div>
      </DefaultLayout>
    );
  }
}
